import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import {
  FaUserAlt,
  FaPhoneAlt,
  FaLocationArrow,
  FaLinkedin,
  FaGithubSquare,
  FaTwitterSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { MdEmail, MdSend } from "react-icons/md";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { headingAnimation, contactAnimation } from "../../hooks/useAnimation";
import { BottomLine } from "../../components";
import "../../components/constant/PrimaryBtn/PrimaryBtn.css";
import "../shared/Shared.css";
import "./Contact.css";

const Contact = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [ref, inView] = useInView({ threshold: 0.3, triggerOnce: true });
  const [viewDiv, setViewDiv] = useState(false);
  const animation = useAnimation();
  const isHomePage = window.location.pathname === '/';

  useEffect(() => {
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
  }, [inView, animation]);

  const handleSend = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_114mailtoss",
        "template_114mailtoss",
        form.current,
        "pvFgC-ao0jB-Afw3G"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your Message has been sent",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="parent py-24 mt-4 ${}"  style={{background: isHomePage ? '#2f3939':'#1c2323' }}>
      <motion.div
        initial="hidden"
        animate={viewDiv && "visible"}
        variants={headingAnimation}
      >
        <h3 className="text-neutral text-center">Feel Free to Contact Me</h3>
        <h1 className="text-4xl font-semibold drop-shadow-md text-center">
          Get In <span className="text-primary">Touch</span>
        </h1>
        <BottomLine />
      </motion.div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={viewDiv && "visible"}
          variants={contactAnimation}
        >
          <h2 className="text-2xl font-medium ">Contact Me</h2>
          <form ref={form} onSubmit={handleSend}>
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6">
              <input
                type="text"
                className={`input-field ${isHomePage ? 'input-field-home':'input-field-contact'}`}
                name="from_name"
                id="from_name"
                placeholder="Name"
                required
              />
              <input
                type="email"
                className={`input-field ${isHomePage ? 'input-field-home':'input-field-contact'}`}
                name="reply_to"
                id="reply_to"
                placeholder="Email"
                required
              />
            </div>
            <input
              type="text"
              className={`input-field ${isHomePage ? 'input-field-home':'input-field-contact'}`}
              name="subject"
              id="subject"
              placeholder="Subject"
              required
            />
            <textarea
              className={`input-field ${isHomePage ? 'input-field-home':'input-field-contact'}`}
              name="message"
              id="message"
              cols="30"
              rows="5"
              placeholder="Message"
              
              required
            ></textarea>
            <button
              type="submit"
              value="Send Message"
              className="primary-button"
            >
              <span>Send</span>{" "}
              <span>
                <MdSend />
              </span>
            </button>
          </form>
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={viewDiv && "visible"}
          variants={contactAnimation}
        >
          <h2 className="text-2xl font-medium">Contact Info</h2>
          <div className="flex items-center my-6">
            <FaUserAlt className="text-2xl mr-8 hover:text-primary cursor-pointer duration-300" />
            <h3 className="font-medium text-primary">Srikant Sahu</h3>
          </div>
          <div className="flex items-center my-6">
            <FaPhoneAlt className="text-2xl mr-8 hover:text-primary cursor-pointer duration-300" />
            <h3 className="font-medium text-primary">+91-700 835 6307</h3>
          </div>
          <div className="flex items-center my-6">
            <MdEmail className="text-2xl mr-8 hover:text-primary cursor-pointer duration-300" />
            <h3 className="font-medium text-primary">
              srikantsahu114@gmail.com
            </h3>
          </div>
          <div className="flex items-center my-6">
            <FaLocationArrow className="text-2xl mr-8 hover:text-primary cursor-pointer duration-300" />
            <h3 className="font-medium text-primary">Sunabeda, Odisha, India</h3>
          </div>
          <div className="mt-8 flex items-center">
            <h3 className="text-xl text-neutral ">Social</h3>
            <div className="bg-gray-400 w-10 h-[2px] mx-4" />
            <a
              href="https://www.linkedin.com/in/srikantsahu114"
              className="text-3xl text-neutral hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://github.com/Srikant114"
              className="text-3xl text-neutral hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaGithubSquare />
            </a>
            <a
              href="https://www.instagram.com/srikantvlogs/"
              className="text-3xl text-neutral hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaInstagramSquare />
            </a>
            <a
              href="https://twitter.com/srikantvlogs"
              className="text-3xl text-neutral hover:text-primary hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
            >
              <FaTwitterSquare />
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
