const Reviews = [
    {
      id: 1,
      name: "Sumit Bagh",
      bio: "Explore Koraput",
      img: "https://res.cloudinary.com/dkysfzs5s/image/upload/v1708237854/srikantPortfolio/testimonial/exploreKoraput.jpg",
      description:
        "I am very impressed to work with him. He is a very good and skilled person. I want to work with him again.",
      rating: 5.0,
    },
    {
      id: 2,
      name: "Sumit Bagh",
      bio: "Explore Koraput",
      img: "https://res.cloudinary.com/dkysfzs5s/image/upload/v1708237854/srikantPortfolio/testimonial/exploreKoraput.jpg",
      description:
        "I am very impressed to work with him. He is a very good and skilled person. I want to work with him again.",
      rating: 5.0,
    },
    {
      id: 3,
      name: "Sumit Bagh",
      bio: "Explore Koraput",
      img: "https://res.cloudinary.com/dkysfzs5s/image/upload/v1708237854/srikantPortfolio/testimonial/exploreKoraput.jpg",
      description:
        "I am very impressed to work with him. He is a very good and skilled person. I want to work with him again.",
      rating: 5.0,
    },

  ];
  
  export default Reviews;