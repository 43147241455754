import React from "react";

const SecondaryBtn = ({ children, className, onClick }) => {
  return (
    <button
      className={`${className} btn text-primary flex items-center gap-2 border-2 border-primary bg-transparent hover:bg-primary hover:text-[#3e3e3e] hover:border-transparent duration-500`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SecondaryBtn;
